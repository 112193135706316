<template>
  <div ref="rootEl" class="news-grid p-xxl">
    <div class="wrapper">
      <h2
        v-if="content.title"
        ref="titleEl"
        class="news-grid__title"
        :class="`title-${content.titleColor}`"
        v-html="content.title"
      ></h2>

      <ul v-if="content.cards" ref="cardsEl" class="news-grid__list">
        <li
          v-for="(item, index) in content.cards"
          :key="index"
          class="news-grid__list__el"
        >
          <Common :content="item" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { onMounted, ref } from 'vue'

import Common from '@/components/card/Common.vue'
import { onAppear } from '@/utils/motion'

import type { PropType } from 'vue'
import type { CardCommon } from '@/components/card/Common.vue'

export interface NewsGrid {
  title: string
  titleColor?: string
  cards: CardCommon[]
}

defineProps({
  content: {
    type: Object as PropType<NewsGrid>,
    required: true,
  },
})

const rootEl = ref<HTMLElement | null>(null)
const titleEl = ref<HTMLElement | null>(null)
const cardsEl = ref<HTMLElement | null>(null)

onMounted(() => {
  if (!rootEl.value) {
    return
  }

  gsap.set(rootEl.value, { opacity: 0 })

  // Animate on appear
  onAppear(
    rootEl.value,
    () => {
      appear()
    },
    {
      once: true,
      rootMargin: '0px 0px -20% 0px',
      waitForHero: false,
    }
  )
})

const appear = () => {
  if (!rootEl.value) {
    return
  }

  const animatedEls: Element[] = [rootEl.value]

  const tl = gsap.timeline({
    onComplete: () => {
      gsap.set(animatedEls, { clearProps: 'all' })
    },
  })

  tl.set(rootEl.value, { clearProps: 'opacity' })

    .set([titleEl.value, cardsEl.value], {
      opacity: 0,
      y: 10,
    })

  tl.add('start')

    .to(
      [titleEl.value, cardsEl.value],
      {
        opacity: 1,
        y: 0,
        ease: '8020',
        delay: 0.55,
        duration: 0.4,
        stagger: 0.15,
      },
      'start'
    )
}
</script>

<style lang="scss" scoped>
$bp: 'm';

.news-grid {
  background: rgba($c-seagull, 0.2);
}

.news-grid__title {
  @include section-title;

  margin-bottom: 4rem;
  text-align: center;

  @include mq($bp) {
    margin-bottom: 8rem;
  }
}

.news-grid__list {
  @extend %list-nostyle;

  display: grid;
  gap: 2rem;

  @include mq($bp) {
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
  }
}

.news-grid__list__el {
  a {
    color: inherit;
  }
}

.news-grid__list__el__figure {
  display: flex;
  align-items: center;
  gap: 3rem;
  margin: 0;

  @include mq($bp) {
    flex-direction: column;
  }
}

.news-figure__image {
  position: relative;
  overflow: hidden;
  min-width: 10rem;
  border-radius: 2rem;
  aspect-ratio: 1;

  img {
    @include image-fit(cover);

    transition: transform 0.3s ease-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  @include mq($bp) {
    width: 100%;
    aspect-ratio: 16 / 10;
  }
}

.news-grid__list__el__title {
  font-weight: 400;
}
</style>
